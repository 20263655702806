// Dependencies
import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import { RichText } from "prismic-reactjs";
import { motion } from "framer-motion";

// Styles
import colors from "styles/colors";
import dimensions from "styles/dimensions";

// Container is a link to the experience page
const ExperienceCardContainer = styled(Link)`
    display: flex;
    justify-content: space-between;
    transition: all 150ms ease-in-out;
    text-decoration: none;
    color: currentColor;

    &:hover {
        box-shadow: 0px 9px 24px rgba(0, 0, 0, 0.1);
        transition: all 150ms ease-in-out;

        // Animate arrow on container hover
        .ProjectCardAction {
            color: ${colors.bqblue500};
            transition: all 150ms ease-in-out;

            span {
                transform: translateX(0px);
                opacity: 1;
                transition: transform 150ms ease-in-out;
            }
        }
    }
`

// Text of the card
const ExperienceCardText = styled("div")`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 2em;

    @media (max-width: ${dimensions.maxwidthMobile}px) {
        padding: 1.5em;
    }
`

// Everything but the details arrow
const ExperienceCardContentUpper = styled("div")`
    display: flex;
    flex-direction: column;
    gap: 1em;

    // Category
    h6 {
        color: ${colors.grey500};
    }

    // Title
    h3 {
        margin: 0;
    }

    // Blurb
    p {
        margin: 0;
        line-height: 1.7;
    }
`

// Details arrow
const ExperienceCardAction = styled("div")`
    font-size: 0.8em;
    font-weight: 600;
    
    text-decoration: none;

    color: currentColor;
    transition: all 150ms ease-in-out;
    
    margin-top: 2em;
    
    span {
        margin-left: 0.75em;
        transform: translateX(-6px);
        display: inline-block;
        transition: transform 300ms ease-in-out;
    }
`

const ExperienceCard = ({ category, title, description, uid }) => {
    return (
        <motion.div>
            <ExperienceCardContainer to={`/work/${uid}`}>

                <ExperienceCardText>
                    <ExperienceCardContentUpper>
                        <h6>{category[0].text}</h6>
                        
                        <h3>{title[0].text}</h3>

                        {RichText.render(description)}
                    </ExperienceCardContentUpper>

                    <ExperienceCardAction className="ExperienceCardAction">
                        Details<span>&#8594;</span>
                    </ExperienceCardAction>
                </ExperienceCardText>

            </ExperienceCardContainer>
        </motion.div>
    );
};

export default ExperienceCard;

// Ensure types for ExperienceCard props
ExperienceCard.propTypes = {
    category: PropTypes.array.isRequired,
    thumbnail: PropTypes.object.isRequired,
    title: PropTypes.array.isRequired,
    description: PropTypes.array.isRequired,
    uid: PropTypes.string.isRequired
}